
export const Environment ={
    /*BENESHTY*/
   /*  baseURL:'https://apitest.geniussystemapi.com',  */
      baseURL:'https://beneshtyapi.geniussystemapi.com', 
    baseURL2:'https://adminapi.beneshty.com',    
    /*kOKYsTORE*/
/*      baseURL:'https://kokystoreapi.geniussystemapi.com',
    baseURL2:'https://admin.kokyandgody.com' */
    /*bEBO*/ 
      /*   baseURL:'https://beboapi.geniussystemapi.com',
    baseURL2:'https://www.adminapi.bebo-eg.com'  */  
 }

export default Environment